<template>
  <div>
    <div class="mb-5">
      <b-modal id="transfer-partial-accept-modal" title="Partial Accept" size="lg" hide-footer @hidden="refreshData">
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded" v-if="Object.keys(transfer).length !== 0">
          <table class="table table-sm table-bordered">
            <tr>
              <th>Product</th>
              <td>{{ transfer.free_purchase_request_item.name }}</td>
            </tr>
            <tr>
              <th>Transfer Amount</th>
              <td>{{ transfer.transfer_amount }}</td>
            </tr>
          </table>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="accepted_amount" class="col-form-label col-form-label-sm">Partial Amount <span class="custom-required">*</span></label>
              <input type="number" v-model="transfer.partial_accepted_amount" v-validate="{ required: true, max: 5, regex: /^[0-9]*$/ }" @change="partialAcceptAmountCheck($event.target.value, transfer.transfer_amount)" id="accepted_amount" name="accepted_amount" class="form-control form-control-sm" placeholder="Enter accept amount"/>
              <div class="invalid-feedback">Partial accept is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="hub_comment" class="col-form-label col-form-label-sm">Comment</label>
              <textarea v-model="transfer.hub_comment" name="hub_comment" id="hub_comment" rows="2" class="form-control form-control-sm" placeholder="Enter comment.."></textarea>
            </div>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="partialAccept">Accept</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('transfer-partial-accept-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'partialAccept',
  props: ['transfer'],
  data() {
    return {
      validation_errors: {},
      loader: false,
      show: false,
    }
  },
  mounted() { },
  methods: {
    partialAccept() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/hub/free/transfer/partial-accept', { transfer: this.transfer }).then(response => {
            this.$bvModal.hide('transfer-partial-accept-modal')
            this.loader = false
            if (response.data.error === false) {
              this.$notification.success({
                message: response.data.message,
              })
              this.$emit('getTransferRequisitionItems')
            } else {
              this.$notification.error({
                message: response.data.message,
              })
            }
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    partialAcceptAmountCheck($event, availableQuantity) {
      const totalAvailableQuantity = availableQuantity
      const acceptAmount = $event
      if (parseFloat(acceptAmount) > parseFloat(totalAvailableQuantity)) {
        this.$notification.error({
          message: 'Partial quantity amount is crossing total transfer amount',
        })
        this.$bvModal.hide('transfer-partial-accept-modal')
      }
    },
    refreshData() {
      this.transfer.partial_accepted_amount = ''
      this.transfer.hub_comment = ''
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
