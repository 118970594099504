<template>
  <div>
    <div class="mb-2">
      <b-modal id="transfer-full-accept-modal" title="Full Accept" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <div class="form-row">
            <div class="col-md-12 col-sm-12 col-lg-12">
              <p>Are you sure received {{ transfer.transfer_amount }} amount of item ?</p>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="accept">Yes Confirm</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('transfer-full-accept-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'fullAccept',
  props: ['transfer'],
  data() {
    return {
      validation_errors: {},
      loader: false,
      show: false,
    }
  },
  mounted() { },
  methods: {
    accept() {
      this.loader = true
      apiClient.post('api/hub/free/transfer/full-accept', { transfer: this.transfer })
        .then(response => {
          this.loader = false
          this.$bvModal.hide('transfer-full-accept-modal')
          this.$notification.success({
            message: response.data.message,
          })
          this.$emit('getTransferRequisitionItems')
        })
        .catch(error => {
          console.log(error)
        })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
