<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-bordered text-center" id="requisition_item_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Product</th>
              <th>Qty</th>
              <th>Status</th>
              <th>Transfer Date</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(transfer, index) in transfers" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ transfer.free_purchase_request_item.name }}</td>
              <td>{{ transfer.transfer_amount }}</td>
              <td>
                <a-tag color="#f50" v-if="transfer.transfer_status == 'Pending'">
                  Pending
                </a-tag>
                <a-tag color="#f50" v-if="transfer.transfer_status == 'Rejected'">
                  Rejected
                </a-tag>
                <a-tag color="#87d068" v-if="transfer.transfer_status == 'Accepted'">
                  Accepted
                </a-tag>
                <a-tag color="#87d068" v-if="transfer.transfer_status == 'Partial'">
                  Partial
                </a-tag>
              </td>
              <td>{{ customDate(transfer.created_at) }}</td>
              <td>
                <a href="javascript: void(0);" v-b-modal.transfer-full-accept-modal @click="$bvModal.show('transfer-full-accept-modal'), fullAccept(transfer)" class="btn btn-sm btn-success mr-1">
                  Accept
                </a>
                <a href="javascript: void(0);" v-b-modal.transfer-partial-accept-modal @click="$bvModal.show('transfer-partial-accept-modal'), partialAccept(transfer)" class="btn btn-sm btn-danger">
                  Partial
                </a>
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
          <h5 class="text-center text-secondary" v-if="transfers.length === 0">
            <Empty :image="simpleImage"></Empty>
          </h5>
        </div>
      </div>
    </div>
    <full-accept :transfer="transfer" @getTransferRequisitionItems="getTransferRequisitionItems"></full-accept>
    <partial-accept :transfer="transfer" @getTransferRequisitionItems="getTransferRequisitionItems"></partial-accept>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import apiClient from '@/services/axios'
import fullAccept from '@/views/freeProduct/purchaseRequest/partial/fullAccept'
import partialAccept from '@/views/freeProduct/purchaseRequest/partial/partialAccept'

export default {
  name: 'List',
  components: { Empty, fullAccept, partialAccept },
  data() {
    return {
      transfers: {},
      transfer: {},
      loading: false,
      spinning: false,
      delayTime: 500,
      current: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getTransferRequisitionItems()
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL')
    },
    getTransferRequisitionItems() {
      this.loading = true
      this.spinning = true
      apiClient.get('api/hub/free/product-pending-transfer/search')
        .then(response => {
          this.loading = false
          this.spinning = false
          if (!response.data.error) {
            const data = response.data
            this.transfers = data.transfers
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    fullAccept(transfer) {
      this.transfer = {}
      this.transfer = transfer
    },
    partialAccept(transfer) {
      this.transfer = {}
      this.transfer = transfer
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
